@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.App {
  text-align: center;
  overflow-y: hidden;
}

.App-header {
  font-family: "Inter", sans-serif;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.vertical{
  margin-top: 4%;
  margin-left: 7%;
  display:flex;
  align-items: center;
  flex-direction:column;
  margin-right: 0;
}

.vertical-centered{
  font-size:18px;
  color:#828282;
  margin-top:17.5%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.text{
  width: 100%;
  text-align: left;
}

.contract-section{
  margin-left:40px;
  height:100vh;
}

.draft-icon {
  margin: 20px 0px;
}

.contract-options{
  display: flex;
}

.App-link {
  color: #61dafb;
}


/* Dark Mode Header */
body {
  background-color: var(--negro); /* Fondo oscuro */
  color: var(--blanco); /* Texto claro */
}

.navbar-dark .navbar-nav .nav-link {
  color: #e0e0e0; /* Asegura que los enlaces sean claros */
}

.navbar-dark .navbar-nav .btn-primary {
  margin-left: 8px; /* Espaciado entre el botón y el enlace de login */
}
