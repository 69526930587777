.editing-section {
    background-color: transparent;
    color: var(--negro);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;
}

.row{
    margin: 0px !important;
}

.content-document, .content {
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    height: 90vh; 
}

.content-document {
    background-color: var(--blanco);
    color: gray;

}

.content {
    background-color: #151515;
    color: var(--blanco)
}
