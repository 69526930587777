.landing-container{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
}

.card-no-border{
    font-size: 4rem;
    font-weight: bold;
    padding-bottom:0;
    border-bottom:0px !important;
}

.content-secondary {
    border-radius: 25px;
    background-color: #151515;
    width: 100%;
    position: relative;
  }