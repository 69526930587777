.content {
  border-radius: 25px;
  background-color: #151515;
  min-height: 82vh;
  width: 100%;
  position: relative;
}

.progress-info {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}

.content-body {
  padding-top: 60px; 
  min-height: 100%; 
}

.progress-tittle{
  color: #ededed;
}

.progress-text{
  color: #8f8f8f;
  margin: 0px;
}

  .hero-content{
    color: var(--blanco);
    max-width: 60%;
    margin:auto;
    position: relative;
    z-index: 1;
  }

  .hero-title{
    font-weight: 600;
    font-size: 4rem;
  }

  .blob{
    z-index: -1;
    width: 440px; 
    height: 440px; 
    border-radius: 24rem; 
    background: linear-gradient(197.37deg, #7450DB -0.38%, rgba(138, 234, 240, 0) 101.89%), linear-gradient(115.93deg, #3E88F6 4.86%, rgba(62, 180, 246, 0.33) 38.05%, rgba(62, 235, 246, 0) 74.14%), radial-gradient(56.47% 76.87% at 6.92% 7.55%, rgba(62, 136, 246, 0.7) 0%, rgba(62, 158, 246, 0.182) 52.16%, rgba(62, 246, 246, 0) 100%), linear-gradient(306.53deg, #2EE4E3 19.83%, rgba(46, 228, 227, 0) 97.33%); 
    background-blend-mode: normal,normal,normal,normal,normal,normal; 
    filter: blur(100px);
    position: absolute;
  }

  .blob-top-left {
    top: 20px; /* Ajusta para posicionar hacia la parte superior izquierda */
    right: 20px; /* Ajusta según sea necesario */
}

.blob-bottom-right {
    bottom: 0px; /* Ajusta para posicionar hacia la parte inferior derecha */
    left: 0px; /* Ajusta según sea necesario */
}
  


/* Input group */

.form-control {
  border-radius: 20px !important; 
  height: 3rem;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  z-index: 2;
  border: none;
}

.hero-button {
  top: 5px !important;
  right: 5px !important;
  background-color: #2658f5 !important;
  color: var(--blanco) !important;
  border-radius: 15px !important;
  min-width: 100px;
}


/* plan section */
.left-panel, .right-panel {
    width: 50%; /* Divide la pantalla a la mitad */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.right-panel {
    border-left: 2px solid #fff; /* Línea divisoria entre paneles */
}

/* Estilo para el botón, ajusta según tu diseño */
.custom-button {
    background-color: #2658f5; /* Color de fondo del botón */
    color: #fff; /* Texto del botón blanco */
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
}

.custom-button:hover {
    background-color: #1b4fbd; /* Color al pasar el mouse */
}



.progreso-contrato {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #151515;
    color: white;
    width: 50%;

}
  