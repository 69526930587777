/* Hero.css */
.hero-section {
    background-color: transparent; /* Fondo oscuro, ajusta según el color exacto de tu header */
    color: var(--negro); /* Texto claro */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .content {
    background-color: #151515; /* Asegura que el fondo del content sea del mismo color que el hero-section */
    max-width: 100%; /* Ajusta según tus necesidades */
    box-sizing: border-box;
    padding: 2rem; /* Aumenta el padding para separar el texto de los bordes */
    border-radius: 25px; /* Borde redondeado */
    margin: auto; /* Centra el contenedor si es necesario */
    width: calc(100vw); /* Considera el padding para mantener el margen */
    min-height: 80vh; /* Asegúrate de que el content no exceda el hero-section */
    display: flex;
    flex-direction: column; /* Organiza el contenido verticalmente */
    justify-content: center; /* Centra el contenido verticalmente */
    position: relative;
  }

  .hero-content{
    color: var(--blanco);
    max-width: 60%;
    margin:auto;
    position: relative;
    z-index: 1;
  }

  .hero-title{
    font-weight: 600;
    font-size: 3.75rem !important;
  }

  .blob{
    z-index: -1;
    width: 440px; 
    height: 440px; 
    border-radius: 24rem; 
    background: linear-gradient(197.37deg, #7450DB -0.38%, rgba(138, 234, 240, 0) 101.89%), linear-gradient(115.93deg, #3E88F6 4.86%, rgba(62, 180, 246, 0.33) 38.05%, rgba(62, 235, 246, 0) 74.14%), radial-gradient(56.47% 76.87% at 6.92% 7.55%, rgba(62, 136, 246, 0.7) 0%, rgba(62, 158, 246, 0.182) 52.16%, rgba(62, 246, 246, 0) 100%), linear-gradient(306.53deg, #2EE4E3 19.83%, rgba(46, 228, 227, 0) 97.33%); 
    background-blend-mode: normal,normal,normal,normal,normal,normal; 
    filter: blur(100px);
    position: absolute;
  }

  .blob2 {
    z-index: 0;
    width: 240px; 
    height: 240px; 
    border-radius: 24rem; 
    background: linear-gradient(197.37deg, #7450DB -0.38%, rgba(138, 234, 240, 0) 101.89%), linear-gradient(115.93deg, #3E88F6 4.86%, rgba(62, 180, 246, 0.33) 38.05%, rgba(62, 235, 246, 0) 74.14%), radial-gradient(56.47% 76.87% at 6.92% 7.55%, rgba(62, 136, 246, 0.7) 0%, rgba(62, 158, 246, 0.182) 52.16%, rgba(62, 246, 246, 0) 100%), linear-gradient(306.53deg, #2EE4E3 19.83%, rgba(46, 228, 227, 0) 97.33%); 
    background-blend-mode: normal, normal, normal, normal, normal, normal; 
    filter: blur(100px);
    position: absolute;
    transform: rotate(45deg); 
    opacity: 0.5;
}

  

  .blob-top-left {
    top: 20px; /* Ajusta para posicionar hacia la parte superior izquierda */
    right: 20px; /* Ajusta según sea necesario */
}

.blob-bottom-right {
    bottom: 0px; /* Ajusta para posicionar hacia la parte inferior derecha */
    left: 0px; /* Ajusta según sea necesario */
}
  


/* Input group */

.form-control {
  border-radius: 20px !important; 
  height: 3rem;
  padding-right: 8.5rem !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  z-index: 10;
  border: none;
}

.hero-button {
  top: 5px !important;
  right: 5px !important;
  background-color: #2658f5 !important;
  color: var(--blanco) !important;
  border-radius: 15px !important;
  z-index: 10;
  position: absolute;
  min-width: 100px;
}





  